import parse from 'html-react-parser';
import { nanoid } from 'nanoid';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as CopyIcon } from '../../assets/images/copy.svg';
import { ReactComponent as PrepProductIcon } from '../../assets/images/prep-product.svg';
import { ReactComponent as SaleProductIcon } from '../../assets/images/sale-product.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/toast-close.svg';
import { convertMarkupToHtml } from '../../helpers/preview-converter';
import { capitalize, formatText, handleCopyClick } from '../../helpers/utils';
import { useWindowSize } from '../../hooks/useWindowSize';
import { addToast } from '../../store/features/toastSlice';
import Menu from '../common/menu';
import { ProductStatus } from '../common/status';

const ProductsListItem = ({ product = {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { width } = useWindowSize();

  const uniqueKey = useRef(nanoid());

  const [expanded, setExpanded] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const { id: productId, name, categories, labels = [], lifecycle, devices } = product;
  const category = categories?.[0] || {};
  const label = labels?.[0] || {};

  const { name: categoryName, usage: categoryUsage } = category;
  const { label_text } = label;
  const { allergens, ingredients } = label_text || {};

  const label_text_string = categoryUsage === 'COMPONENT' ? allergens || '' : ingredients || '';

  const devicesListName = devices?.map(item => item?.name)?.join(', ') || '';

  const isEllipsisActive = e => {
    const labelDiv = document.getElementById(`label-${uniqueKey.current}`);
    return labelDiv?.offsetWidth < labelDiv?.scrollWidth;
  };

  const renderIngredientsOrAllergens = () => {
    const data = label_text_string;
    if (data.length > 0) {
      const dataAsString = data.replace(/\n\n/g, ', ').replace(/\n /g, '');
      const formattedString = convertMarkupToHtml(dataAsString);
      const htmlCode = parse(formattedString);
      return htmlCode;
    }
    return null;
  };

  const renderExpandedIngredientsOrAllergens = () => {
    const data = label_text_string;
    if (data.length > 0) {
      const formattedString = convertMarkupToHtml(data);
      const htmlCode = parse(formattedString);
      return htmlCode;
    }
    return null;
  };

  useEffect(() => {
    setShowMore(isEllipsisActive());
  }, [labels, allergens, width]);

  const navigateToProductDetails = () => {
    navigate(`/products/product-details/${productId}`);
  };

  const onCopyClick = async (value, key) => {
    const response = await handleCopyClick(value);
    if (response) {
      dispatch(
        addToast({
          error: false,
          text: `${key} copied successfully`,
          id: nanoid(),
        }),
      );
    }
  };
  return (
    <Fragment>
      <ProductsListItemWrapper
        className="data-container py-2 border-bottom cursor"
        onClick={() => navigateToProductDetails()}>
        <div className="flex flex-column justify-center overflow-hidden py-2_5 gap-05">
          <label className="medium-text font-16 one-line">{name}</label>
          {devicesListName && <span className="font-12 main-grey-text one-line">{devicesListName}</span>}
        </div>
        <div className="flex items-center overflow-hidden">
          <div className="overflow-hidden flex items-center">
            {categoryUsage === 'COMPONENT' ? (
              <Fragment>
                <PrepProductIcon />
                <span className="regular-text ml-2">Prep</span>
              </Fragment>
            ) : (
              <Fragment>
                <SaleProductIcon />
                <span className="regular-text ml-2">Sale</span>
              </Fragment>
            )}
          </div>
        </div>
        <div className="flex items-center overflow-hidden">
          <label className="regular-text font-16 overflow-hidden one-line">{categoryName}</label>
        </div>
        <div className="flex items-center overflow-hidden">
          <label
            className="product-list regular-text font-16 overflow-hidden one-line flex"
            id={`label-${uniqueKey.current}`}
            style={{ width: `90%` }}>
            {renderIngredientsOrAllergens()}
          </label>
          {showMore && <span className="dots">...</span>}
          {showMore && (
            <span
              className="regular-text primary-text nowrap"
              onClick={e => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}>
              {expanded ? 'show less' : 'show more'}
            </span>
          )}
        </div>
        <div className="flex items-center overflow-hidden">
          <ProductStatus status={lifecycle} />
        </div>
        <div className="flex items-center justify-center">
          <Menu menuList={[{ name: t('DETAILS'), onClick: () => navigateToProductDetails() }]} />
        </div>
      </ProductsListItemWrapper>
      {showMore && expanded && (
        <ProductsListItemExpandWrapper className="pxy-5 flex-column border-bottom">
          <div className="flex items-center mb-3">
            <div className="flex flex-1 items-center">
              <label className="bold-text">{categoryUsage === 'COMPONENT' ? 'ALLERGENS' : 'INGREDIENTS'}</label>
              <div
                className="flex radius-2 items-center justify-center pxy-1 ml-2 cursor additional-background"
                onClick={() =>
                  onCopyClick(
                    label_text_string,
                    capitalize(formatText(categoryUsage === 'COMPONENT' ? 'ALLERGENS' : 'INGREDIENTS')),
                  )
                }>
                <CopyIcon className="primary-text" />
              </div>
            </div>
            <div className="flex items-center justify-center pxy-1 cursor">
              <CrossIcon onClick={() => setExpanded(false)} />
            </div>
          </div>
          <label className="regular-text font-16">{renderExpandedIngredientsOrAllergens()}</label>
        </ProductsListItemExpandWrapper>
      )}
    </Fragment>
  );
};

const ProductsListItemWrapper = styled.div`
  min-height: 60px;

  .product-list {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }
  .dots {
    display: block;
    margin-right: 10px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }

  .status-text {
    font-size: 14px;
  }
`;

const ProductsListItemExpandWrapper = styled.div`
  .allergence-expand-item {
    background-color: ${({ theme }) => theme.colors.users_teams_background};
  }
`;

export default ProductsListItem;
