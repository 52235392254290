import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../assets/images/BG.svg';
import AccountActivation from '../../components/AccountActivation';

const Activate = () => {
  return (
    <AccountActivationWrapper className="flex justify-center h-100-vh overflow-scroll">
      <AccountActivation />
    </AccountActivationWrapper>
  );
};

const AccountActivationWrapper = styled.div`
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: scroll;
`;

export default Activate;
