import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import api from '../../store/services';
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')),
    userDetails: JSON.parse(localStorage.getItem('user'))?.user,
    userOrganizations: JSON.parse(localStorage.getItem('user'))?.organizations,
    userOrganization: JSON.parse(localStorage.getItem('user'))?.organizations?.[0] || {},
    loading: false,
    error: {},
    forgotPasswordStep: 'EMAIL',
    email: '',
    password: '',
    expDate: '',
    invite: true,
    requiredFields: null,
    shouldRedirect: null,
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
      state.loadingType = payload.loadingType;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload.error;
      state.errorType = payload.errorType;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
      state.userDetails = payload?.user;
      state.userOrganizations = payload?.organizations;
      state.userOrganization = payload?.organizations?.[0];
    },
    setForgotPasswordStep: (state, { payload }) => {
      state.forgotPasswordStep = payload;
    },
    setInvite: (state, { payload }) => {
      state.invite = payload;
    },
    setRedirect: (state, { payload }) => {
      state.shouldRedirect = payload;
    },
  },
});

export const loginUser = payload => async dispatch => {
  dispatch(setLoading({ loading: true, loadingType: 'LOGIN' }));
  try {
    const { data } = await api.get(`/api/user/authenticate`, payload);
    dispatch(setUser(data));
    dispatch(setLoading({ loading: false, loadingType: '' }));
  } catch (error) {
    dispatch(setError({ error: { ...error.response.data }, errorType: 'LOGIN' }));
  }
};

export const sendOtpEmail = payload => async dispatch => {
  dispatch(setLoading({ loading: true, loadingType: 'FORGOT_PASSWORD' }));
  try {
    await api.get(`/api/passwords/otp`, payload);
    dispatch(setForgotPasswordStep('OTP'));
    dispatch(setLoading({ loading: false, loadingType: '' }));
  } catch (error) {
    dispatch(setError({ error: { ...error.response.data }, errorType: 'FORGOT_PASSWORD' }));
  }
};

export const validateOtp = payload => async dispatch => {
  dispatch(setLoading({ loading: true, loadingType: 'FORGOT_PASSWORD' }));
  try {
    await api.get(`/api/otp/validate`, payload);
    dispatch(setForgotPasswordStep('PASSWORD_RESET'));
    dispatch(setLoading({ loading: false, loadingType: '' }));
  } catch (error) {
    dispatch(setError({ error: { ...error.response.data }, errorType: 'FORGOT_PASSWORD' }));
  }
};

export const changePassword = payload => async dispatch => {
  dispatch(setLoading({ loading: true, loadingType: 'FORGOT_PASSWORD' }));
  try {
    await api.get(`/api/adminusers/passwords`, payload, payload?.header);
    dispatch(setForgotPasswordStep('PASSWORD_CHANGED'));
    dispatch(setLoading({ loading: false, loadingType: '' }));
  } catch (error) {
    dispatch(setError({ error: { ...error.response.data }, errorType: 'FORGOT_PASSWORD' }));
  }
};

export const getRequiredField = token => async dispatch => {
  return Axios.get(`${process.env.REACT_APP_API_URL}/users/activate/required_fields`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(({ data }) => {
      return Promise.resolve({ data });
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const activateUser =
  ({ token, request }) =>
  async dispatch => {
    return Axios.post(`${process.env.REACT_APP_API_URL}/users/activate`, request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(({ data }) => {
        dispatch(setRedirect(true));
        return Promise.resolve(data);
      })
      .catch(error => {
        dispatch(setInvite(false));
        return Promise.reject(error);
      });
  };

export const getPasswordRequirements = token => async dispatch => {
  return Axios.get(`${process.env.REACT_APP_API_URL}/users/password_requirements`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const { setUser, setLoading, setError, setForgotPasswordStep, setInvite, setRedirect } = userSlice.actions;
export default userSlice.reducer;
