import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { uppercase } from '../../helpers/utils';
import NoDataComponent from '../common/no-data-component';
import NoResultComponent from '../common/no-result-component';
import UserListItem from './user-list-item';

const usersTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'PHONE', key: 'PHONE' },
  { name: 'EMAIL', key: 'EMAIL' },
  { name: 'STATUS', key: 'STATUS' },
  { name: '', key: 'MENU' },
];

const UsersList = ({ debouncedSearch, filter, updateUsersList, userOrganization }) => {
  const { users } = useSelector(state => state.usersManagement);
  const { content: usersList, last } = users || {};

  const renderTableHeader = headers => {
    return (
      <div className="border-bottom header-container">
        {headers.map(header => (
          <label key={header.key} className="flex items-center bold-text px-6 font-12">
            {uppercase(header.name)}
          </label>
        ))}
      </div>
    );
  };

  return (
    <UsersListWrapper className="flex-column flex-1 mt-6 card mx-2 overflow-hidden">
      {usersList?.length > 0 ? (
        <Fragment>
          {renderTableHeader(usersTableHeaders)}
          <div className="flex-column flex-1 overflow-scroll">
            {usersList?.map(user => (
              <UserListItem
                key={user?.id}
                user={user}
                updateUsersList={updateUsersList}
                userOrganization={userOrganization}
              />
            ))}
          </div>
        </Fragment>
      ) : debouncedSearch || filter?.value?.id ? (
        <NoResultComponent />
      ) : (
        <NoDataComponent />
      )}
    </UsersListWrapper>
  );
};

const UsersListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr) minmax(100px, 140px) 60px;
  }

  .header-container {
    min-height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default UsersList;
