import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { ReactComponent as ReloadIcon } from '../../assets/images/recycle-icon.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getDevicesDetails } from '../../store/features/deviceSlice';
import { getStorageItem, setStorageItem } from '../../store/features/storageGroupsSlice';
import { addToast } from '../../store/features/toastSlice';
import Button from '../Elements/button/button';
import TableHeader from '../common/table-header';
import DeviceItem from './device-item';

const storageTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'USE BY', key: 'USE_BY' },
  { name: 'STATE', key: 'STATE' },
  { name: '', key: 'MENU' },
];

const DeviceDetailsRight = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { deviceDetails } = useSelector(state => state.device);
  const { id: device_id, storage_rule_group } = deviceDetails || {};
  const { is_saas_default } = storage_rule_group || {};

  const { setModal, hasPermission, organizationParentChildIds, loadingOrganizationParentChilds } =
    useContext(OrganisationContext);

  const [loading, setLoading] = useState(true);
  const [storageRulesList, setStorageRulesList] = useState([]);

  const onChangeStorageRules = data => {
    setModal({
      type: 'change-storage-rules',
      content: {
        id: device_id,
        storageRulesItemlist: data,
        onSuccess: onChangeGroupSuccess,
      },
    });
  };

  const onStorageRulesListUpdate = updatedStorage => {
    const updatedDeviceList = storageRulesList.map(storagerule =>
      storagerule.id === updatedStorage.id ? updatedStorage : storagerule,
    );
    setStorageRulesList(updatedDeviceList);
  };

  const fetchStorageRules = () => {
    setLoading(true);
    // const fetchParams = is_saas_default ? { device_id: device_id } : storage_rule_group ? { device_id: device_id } : {};

    dispatch(
      getStorageItem({
        params: {
          // lifecycle: 'ACTIVE',
          device_id: device_id,
        },
      }),
    )
      .then(data => {
        const SaasDefaultFalseValues = data?.filter(item => item?.group?.is_saas_default === false || !item?.group);
        setStorageRulesList(SaasDefaultFalseValues);
        if (!(SaasDefaultFalseValues?.length > 0)) {
          onChangeStorageRules(data);
        }
      })
      .catch(error => {
        dispatch(addToast({ error: true, text: 'Error while fetching storage list' }));
      })
      .finally(() => setLoading(false));
  };

  const onChangeGroupSuccess = () => {
    try {
      dispatch(
        getDevicesDetails({
          device_id: device_id,
        }),
      );
      fetchStorageRules(0, false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (device_id && !loadingOrganizationParentChilds) {
      fetchStorageRules(0, false);
    }

    return () => {
      dispatch(setStorageItem([]));
    };
  }, [device_id, organizationParentChildIds, loadingOrganizationParentChilds]);

  const onAddSotrageRules = storage => {
    setModal({
      type: 'add-storage',
      content: {
        isCreateStorageRuels: true,
        isFromDeviceDetails: true,
        id: device_id,
        onSuccess: fetchStorageRules,
      },
    });
  };

  const onChangeGroup = () => {
    setModal({
      type: 'change-group',
      content: {
        id: device_id,
        onSuccess: onChangeGroupSuccess,
      },
    });
  };

  return (
    <DeviceDetailsRightWrapper className="radius-4 card flex-1 flex-column">
      <div className="border-bottom justify-between details-header flex items-center pxy-6">
        <label className="bold-text font-24 flex-1 text-capitalize">{t('STORAGE_RULES')}</label>
      </div>
      <div className="border-bottom justify-between details-header flex items-center px-6 py-5">
        <label className="bold-text font-20 flex-1">
          {storage_rule_group ? `${storage_rule_group?.name} Setting` : t('LOCAL_SETTINGS')}
        </label>
        <div className="flex gap-4 items-center">
          {hasPermission('DEVICES_MANAGE') && !storage_rule_group && (
            <Button
              label="Add new"
              icon={<PlusIcon className="primary-text" />}
              className="additional specified-width"
              width="110px"
              size="tiny"
              onClick={onAddSotrageRules}
              disabled={loading}
            />
          )}
          {hasPermission('STORAGE_DEVICE_MANAGE') && (
            <Button
              label="Change group"
              icon={<ReloadIcon className="primary-text" />}
              className="additional specified-width"
              width="135px"
              size="tiny"
              onClick={onChangeGroup}
              disabled={loading}
            />
          )}
        </div>
      </div>
      <div className="details-container flex-column flex-1 overflow-scroll radius-4">
        {loading ? (
          <div className="item flex items-center justify-center flex-1">
            <img alt="loading" height="40px" src={Loading} />
          </div>
        ) : (
          <Fragment>
            <TableHeader headers={storageTableHeaders} />
            {storageRulesList.length > 0 ? (
              <div className="storage-wrapper">
                {storageRulesList.map(storage => (
                  <DeviceItem
                    storage={storage}
                    key={storage.id}
                    fetchStorageRules={fetchStorageRules}
                    onStorageRulesListUpdate={onStorageRulesListUpdate}
                  />
                ))}
              </div>
            ) : (
              <div className="flex-column items-center justify-center flex-1">
                <label className="medium-text font-16 main-grey-text mt-6">{t('NO_STORAGE_LIST')}</label>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </DeviceDetailsRightWrapper>
  );
};

export const DeviceDetailsRightWrapper = styled.div`
  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }
  .input {
    height: 32px;
    font-size: 14px;
    padding-left: 36px;
    line-height: 32px;
  }
  .search-icon {
    width: 16px;
    height: 16px;
    top: 8px;
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(200px, 1fr) minmax(150px, 1fr) minmax(150px, 1fr) 40px;
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    min-height: 32px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default DeviceDetailsRight;
