import styled from 'styled-components';
import BackgroundImage from '../../assets/images/BG.svg';

export const ForgotPasswordWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  overflow: scroll;

  .back-icon-container {
    top: 23%;
    left: 100px;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(103, 103, 103, 0.08);
    border-radius: 16px;
    width: 40px;
    height: 40px;

    .back-icon {
      path {
        stroke: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  .forgot-password-content {
    width: 456px;

    .otp-content {
      margin-top: 24px;

      .otp-input {
        margin: 0px 4px;

        .input-style {
          width: 50px;
          height: 50px;
          text-align: center;
          font-size: 16px;
          border-radius: 16px;
        }
      }
    }

    .password-requirement-tooltip {
      position: relative;
      .password-requirement-tooltip-details {
        position: relative;
      }
      .error-icon {
        path {
          fill: ${({ theme }) => theme.colors.additional_red};
        }
      }
      .tooltip-content {
        height: fit-content;
        width: fit-content;
        max-width: fit-content;
        z-index: 10;
        margin: 4px 0;
        box-shadow: 2px 8px 30px rgb(5 49 73 / 10%);
        padding: 12px 16px;

        &::before {
          width: 0px;
          height: 0px;
        }

        #arrow,
        #arrow::before {
          position: absolute;
          width: 16px;
          height: 16px;
          background: inherit;
          background: #ffffff;
        }

        #arrow {
          visibility: hidden;
        }

        #arrow::before {
          visibility: visible;
          content: '';
          transform: rotate(45deg);
        }
      }

      .tooltip-content[data-placement^='right'] > #arrow {
        left: -6px;
      }
    }
  }

  .show-password {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 12px;
    cursor: pointer;
  }

  .disabled-resend {
    opacity: 0.5;
  }
`;
