import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as GpsIcon } from '../../assets/icons/contact/gps.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/contact/mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/contact/phone.svg';
import { ReactComponent as MasqueradeIcon } from '../../assets/icons/masquerade.svg';
import { ReactComponent as SubscriptionIcon } from '../../assets/icons/subscription.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { setItemInLocalStorage } from '../../helpers/localstorage';
import { getContactValueByType } from '../../helpers/utils';
import { onAccessAccount } from '../../store/features/accountsSlice';
import { addToast } from '../../store/features/toastSlice';
import { setUser } from '../../store/features/userSlice';
import IconContainer from '../common/icon-container';
import CustomTooltip from '../common/tooltip-new';

const DetailsHeader = ({ fromMyAccount }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { hasPermission } = useContext(OrganisationContext);

  const { user } = useSelector(state => state.user);
  const { accountDetails } = useSelector(state => state.accounts);

  const [loading, setLoading] = useState(false);

  const getLocationValue = locations => {
    const primaryLocation = locations?.find(l => l.is_primary);
    return primaryLocation || locations?.[0] || {};
  };

  const { name, locations, contact_details, account_owners } = accountDetails;
  const contactEmail = getContactValueByType(contact_details, 'EMAIL');
  const contactPhone = getContactValueByType(contact_details, 'MOBILE');
  const accountLocation = getLocationValue(locations);

  const onAccessAccountClick = accountDetails => {
    const account_id = accountDetails.id;
    setLoading(true);
    dispatch(onAccessAccount({ organization_id: account_id }))
      .then(data => {
        const api_tokens = user.api_tokens;
        const organizations = user.organizations;
        const updatedUser = {
          ...user,
          original_api_tokens: { ...api_tokens },
          original_organizations: organizations,
          api_tokens: { ...data.api_tokens },
          accountDetails: accountDetails,
          isAccessAccount: true,
          organizations: data.organizations,
          selectedAccount: { ...accountDetails },
        };
        setItemInLocalStorage('user', updatedUser);
        dispatch(setUser(updatedUser));
        setLoading(false);
        navigate('/home');
        window.location.reload();
        dispatch(addToast({ error: false, text: 'Account has been accessed' }));
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
        dispatch(addToast({ error: true, text: 'Account cannot be accessed' }));
      });
  };

  useEffect(() => {
    console.log('NODE_ENV', process.env.NODE_ENV);
    if (!fromMyAccount) return;
    if (window.Chargebee) {
      window.Chargebee.registerAgain();
      return;
    }
    const script = document.createElement('script');

    script.onload = () => {
      window.Chargebee.init({
        site: process.env.NODE_ENV === 'production' ? 'lablit' : 'lablit-test',
      });
      window.Chargebee.registerAgain();
    };

    script.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [fromMyAccount]);

  return (
    <DetailsHeaderWrapper
      className={classNames(
        'mb-6 mx-2 card px-6 py-4',
        fromMyAccount && 'portal-grid',
        !fromMyAccount && hasPermission('MASQUERADE') && 'masquerade-grid',
      )}>
      <div className="flex-column row-gap-3">
        <label className="semibold-text font-20">{name}</label>
        <label className="semibold-text o05">{account_owners?.[0]?.name || '-----'}</label>
      </div>
      <div className="flex-column row-gap-2">
        <div className="flex items-center">
          <div className="flex items-center pxy-1 radius-2 icon-container primary-text">
            <PhoneIcon />
          </div>
          <label className={classNames('regular-text ml-2', !contactPhone.contact_value && 'main-grey-text')}>
            {contactPhone.contact_value || 'Not provided'}
          </label>
        </div>
        <div className="flex items-center">
          <div className="flex items-center pxy-1 radius-2 icon-container primary-text">
            <MailIcon />
          </div>
          <label className={classNames('regular-text ml-2', !contactEmail.contact_value && 'main-grey-text')}>
            {contactEmail.contact_value || 'Not provided'}
          </label>
        </div>
      </div>
      <div>
        <div className="flex items-start">
          <div className="flex items-center pxy-1 radius-2 icon-container primary-text">
            <GpsIcon />
          </div>
          <label className={classNames('regular-text ml-2', !accountLocation.formatted_address && 'main-grey-text')}>
            {accountLocation.formatted_address || 'Not provided'}
          </label>
        </div>
      </div>
      {fromMyAccount && (
        <a href="javascript:void(0)" data-cb-type="portal">
          <CustomTooltip
            place={'bottom'}
            id="manage-subscription"
            content={
              <div className="pxy-2">
                <label className="regular-text">Manage subscription</label>
              </div>
            }>
            <IconContainer
              Icon={SubscriptionIcon}
              iconColor="primary"
              backgroundColor="light_grey"
              iconContainerClassname="cursor"
            />
          </CustomTooltip>
        </a>
      )}
      {!fromMyAccount && hasPermission('MASQUERADE') && (
        <div className="flex items-center justify-center">
          <IconContainer
            Icon={MasqueradeIcon}
            iconColor="black_text"
            backgroundColor="light_grey"
            iconContainerClassname="cursor pxy-2"
            onClick={() => !loading && onAccessAccountClick(accountDetails)}
          />
        </div>
      )}
    </DetailsHeaderWrapper>
  );
};

export const DetailsHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(300px, 1.5fr) minmax(300px, 1.5fr);

  &.portal-grid {
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1.5fr) minmax(300px, 1.5fr) 42px;
  }

  &.masquerade-grid {
    grid-template-columns: minmax(300px, 1fr) minmax(300px, 1.5fr) minmax(300px, 1.5fr) 42px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.light_grey_background};
  }
`;

export default DetailsHeader;
