import isEquals from 'lodash.isequal';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { createFilterUrl } from '../../helpers/utils';
import { getPrintLogList, setPrintLogList } from '../../store/features/reportsSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import NoDataComponent from '../common/no-data-component';
import TableHeader from '../common/table-header';
import PrintLogListItem from './print-log-list-item';

const printLogTableHeaders = [
  { name: 'ITEM', key: 'ITEM' },
  { name: 'STORAGE', key: 'STORAGE' },
  { name: 'INITIALS', key: 'INITIALS' },
  { name: 'PRINTED', key: 'PRINTED' },
  { name: 'DEVICE', key: 'DEVICE' },
];

const PrintLogList = ({ filters = [], organizationFilter = null }) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);
  const filterRef = useRef({});

  const { printLogList } = useSelector(state => state.reports);
  const { content: printLogs, last } = printLogList || {};

  const [loading, setLoading] = useState(true);

  const fetchPrintLogList = (page, merge, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
    }
    dispatch(
      getPrintLogList({
        merge,
        params: {
          sort_by: 'printed_date',
          order_by: 'DESC',
          page: page,
          size: 20,
          organization_id: organizationFilter ? organizationFilter?.map(org => org.value)?.join(',') : null,
          ...filterRef.current,
        },
      }),
    )
      .catch(() => {})
      .finally(() => setLoading(false));
  };

  const fetchMoreData = () => {
    fetchPrintLogList(pageRef.current + 1, true, false);
  };

  useEffect(() => {
    const updatedFilter = createFilterUrl(filters);

    if (!isEquals(updatedFilter, filterRef.current)) {
      filterRef.current = updatedFilter;
      fetchPrintLogList(0, false, true);
    }
  }, [filters]);

  useEffect(() => {
    fetchPrintLogList(0, false, true);

    return () => {
      dispatch(setPrintLogList([]));
    };
  }, [organizationFilter]);

  return (
    <PrintLogListWrapper className="flex-column flex-1 card mt-6 mx-2 overflow-hidden">
      {loading ? (
        <div className="item flex items-center justify-center flex-1">
          <img alt="loading" height="40px" src={Loading} />
        </div>
      ) : (printLogs || []).length > 0 ? (
        <Fragment>
          <TableHeader headers={printLogTableHeaders} />
          <InfiniteScrollComponent
            dataLength={printLogs.length}
            hasMore={!last}
            height={64}
            fetchMoreData={fetchMoreData}>
            {(printLogs || []).map(logItem => (
              <PrintLogListItem logItem={logItem} key={logItem?.id} />
            ))}
          </InfiniteScrollComponent>
        </Fragment>
      ) : (
        <NoDataComponent />
      )}
    </PrintLogListWrapper>
  );
};

export const PrintLogListWrapper = styled.div`
  .header-container,
  .data-container {
    display: grid;
    grid-template-columns:
      minmax(150px, 1fr) minmax(130px, 1fr) 80px minmax(220px, 1fr)
      minmax(150px, 1fr);
    column-gap: 48px;
    padding: 0 16px 0 24px;
  }

  .header-container {
    height: 40px;
  }
`;

export default PrintLogList;
