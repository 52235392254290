import { createSlice } from '@reduxjs/toolkit';

export const reloadSlice = createSlice({
  name: 'reload',
  initialState: {
    updated: false,
  },
  reducers: {
    init: state => {
      state.updated = false;
      state.serviceWorkerRegistration = null;
    },
    update: (state, { payload }) => {
      state.updated = true;
      state.serviceWorkerRegistration = payload;
    },
  },
});

export const { update, init } = reloadSlice.actions;
export default reloadSlice.reducer;
