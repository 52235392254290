import { nanoid } from 'nanoid';
import React, { Fragment, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import CategoryDetails from '../../components/category-details';
import CategoryList from '../../components/category-list';
import OrganizationFilter from '../../components/common/organization-filter';
import PageHeader from '../../components/page-header';
import { multipleFilterTypeEnum } from '../../constant/filter-option-data';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';

const Categories = () => {
  const navigate = useNavigate();
  const { setModal } = useContext(OrganisationContext);

  const { categoryDetails } = useSelector(state => state.category);

  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState([{ id: nanoid() }]);
  const [organizationFilter, setOrganizationFilter] = useState(null);
  const debouncedSearch = useDebounce(searchText, 500);

  const onAddClick = () => {
    setModal({
      type: 'category-add-edit',
      content: { isNew: true, onSuccess: data => navigate(`category-details/${data.id}`) },
    });
  };

  const onChangeOrganizationFilter = selected => {
    setOrganizationFilter(selected);
    setFilters([{ id: nanoid() }]);
  };

  return (
    <CategoriesWrapper className="flex-column w-full">
      <Routes>
        <Route
          path="category-details/:categoryId"
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: 'Categories', path: '/categories' },
                  ...(categoryDetails?.name ? [{ text: categoryDetails?.name, onClick: () => {} }] : []),
                ]}
                showBackButton
                onBackClick={() => navigate('/categories')}
              />
              <CategoryDetails />
            </Fragment>
          }
        />
        <Route
          element={
            <Fragment>
              <PageHeader
                className={organizationFilter?.length ? 'items-start' : ''}
                title="Categories"
                showAddNewBtn={true}
                onAddClick={onAddClick}
                showSearch={true}
                addBtnPermission="CATEGORIES_MANAGE"
                searchText={searchText}
                onSearchChange={setSearchText}
                showMultipleFilter
                multiFilter={filters}
                setMultiFilter={setFilters}
                multiFilterFor={multipleFilterTypeEnum.category}
                showCustomSearchableDropdownFiled={true}
                customSearchableDropdownFiled={() => (
                  <OrganizationFilter
                    organizationFilter={organizationFilter}
                    setOrganizationFilter={onChangeOrganizationFilter}
                    isRemoveParents={true}
                  />
                )}
                organizationFilter={organizationFilter}
              />
              <CategoryList
                debouncedSearch={debouncedSearch}
                filters={filters}
                organizationFilter={organizationFilter}
              />
            </Fragment>
          }
          path="/*"
        />
      </Routes>
    </CategoriesWrapper>
  );
};

const CategoriesWrapper = styled.div``;

export default Categories;
