import React from 'react';
import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

const CustomTooltip = ({
  id = '',
  tooltipId = '',
  place = undefined,
  clickable = false,
  children,
  content,
  wrapperClassName = '',
  hidden = false,
  noArrow = false,
  isOpen = undefined,
  onBlurTooltip = () => {},
  opacity = 1,
  positionStrategy = undefined,
}) => {
  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      onBlurTooltip();
    }
  };

  const focusToolTip = () => {
    const element = document.getElementById(`tooltip-container-${id || tooltipId}`);
    element && element.focus();
  };

  return (
    <CustomTooltipWrapper
      onBlur={onBlur}
      tabIndex={-1}
      data-tooltip-id={id}
      className={wrapperClassName}
      id={`tooltip-container-${id || tooltipId}`}>
      {children}
      <Tooltip
        opacity={opacity}
        positionStrategy={positionStrategy}
        id={id || tooltipId}
        place={place}
        clickable={clickable}
        className="custom-tooltip"
        classNameArrow="custom-arrow"
        hidden={hidden}
        noArrow={noArrow}
        isOpen={isOpen}
        afterShow={focusToolTip}>
        {content}
      </Tooltip>
    </CustomTooltipWrapper>
  );
};

const CustomTooltipWrapper = styled.div`
  .custom-tooltip {
    background-color: #fff;
    color: ${({ theme }) => theme.colors.black_text};
    border-radius: 12px;
    box-shadow: 0px 3px 4px 3px #1018281a;
    padding: 0px;
    z-index: 10;

    .custom-arrow {
      box-shadow: 3px 3px 6px 0px #1018281a;
    }
  }
`;

export default CustomTooltip;
