import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import ReloadIcon from '../../assets/images/reload.svg';
import Button from '../../components/Elements/button/button';
import { init } from '../../store/features/reloadSlice';

export const RefreshPopup = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.reload);

  return (
    state.updated && (
      <RefreshPopupWrapper className="fixed flex justify-center pxy-6">
        <div className="flex flex-column items-center card mt-10 px-12 pt-10 pb-12 refresh-view">
          <label className="bold-text font-24">New version available!</label>
          <label className="regular-text lighter-text text-center mt-2 mx-14">
            Please refresh the page to get access to new platform updates
          </label>
          <img className="mt-10" src={ReloadIcon} />
          <Button
            label="Refresh"
            className="primary mt-10"
            borderRadius="16px"
            width="100%"
            size="medium"
            onClick={() => {
              state.serviceWorkerRegistration?.waiting?.postMessage({ type: 'SKIP_WAITING' });
              dispatch(init());
              window.location.reload();
            }}
          />
        </div>
      </RefreshPopupWrapper>
    )
  );
};

export const RefreshPopupWrapper = styled('div')`
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.modalBackground};
  backdrop-filter: blur(8px);

  .refresh-view {
    height: 420px;
    width: 460px;
  }
`;
