import { nanoid } from 'nanoid';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { initModal } from '../../constant/InitialData';
import { OrganisationContext } from '../../context/organisationContext';
import { addToast } from '../../store/features/toastSlice';
import CommonPopup from '../common-popup';

import { changeGroupStorageRulesDevice, createStorageRules } from '../../store/features/storageGroupsSlice';

const ChangeStorageRules = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { modal, setModal, organizationParentChilds } = useContext(OrganisationContext);
  const { onSuccess, id: device_id, storageRulesItemlist = [] } = modal.content;

  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    setLoading(true);
    const request = {
      id: device_id,
    };

    await Promise.all(
      storageRulesItemlist?.map(item =>
        dispatch(
          createStorageRules({
            request: {
              name: item?.name,
              storage_type: item?.storage_type,
              expiry: {
                units: item?.expiry?.units,
                period: item?.expiry?.period,
              },
              organizations: organizationParentChilds.map(org => ({ id: org.id })),
              lifecycle: item?.lifecycle,
            },
          }),
        ),
      ),
    )
      .then(async data => {
        await Promise.all(
          data?.map(item => dispatch(changeGroupStorageRulesDevice({ rules_id: item?.id, request: [request] }))),
        ).then(() => {
          dispatch(addToast({ success: true, text: t('SUCCESSFULLY_CHANGE_GROUP'), id: nanoid() }));
          onSuccess(data);
          setModal(initModal);
        });
      })
      .catch(error => {
        const errorText = error?.response?.data?.error_description || t('ERROR_STORAGE_RULES_GROUP');
        dispatch(addToast({ error: true, text: errorText, id: nanoid() }));
      })
      .finally(() => setLoading(false));
  };

  return (
    <CommonPopup
      confirmButtonProps={{ label: t('ADD') }}
      cancelButtonProps={{ label: t('CANCEL') }}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirm}
      disabled={loading}>
      <ChangeGroupWrapper className="flex-column row-gap-6 mt-6 items-center w-full">
        <div className="flex items-center">
          <label className="bold-text font-24 text-center mb-2">{t('ADD_DEFAULT_STORAGE_RULES')}</label>
        </div>
      </ChangeGroupWrapper>
    </CommonPopup>
  );
};

const ChangeGroupWrapper = styled.div``;

export default ChangeStorageRules;
