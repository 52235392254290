import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { ReactComponent as NoDeviceIcon } from '../../assets/images/no-device.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { OrganisationContext } from '../../context/organisationContext';
import { getCategoryDevices, setCategoryDevices } from '../../store/features/categorySlice';
import Button from '../Elements/button/button';
import DeviceItem from './device-item';

const CategoryDetailsRight = () => {
  const dispatch = useDispatch();
  const { categoryId } = useParams();

  const { setModal, hasPermission } = useContext(OrganisationContext);

  const { categoryDevices } = useSelector(state => state.category);

  const [loading, setLoading] = useState(true);

  const fetchCategoryDevices = categoryId => {
    setLoading(true);
    dispatch(
      getCategoryDevices({
        categoryId: categoryId,
      }),
    )
      .catch(error => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (categoryId) {
      fetchCategoryDevices(categoryId);
    }

    return () => {
      dispatch(setCategoryDevices([]));
    };
  }, [categoryId]);

  const onRemoveDevice = device => {
    setModal({
      type: 'remove-device',
      content: {
        device: device,
        idValue: categoryId,
        key: 'CATEGORY',
        onSuccess: () => fetchCategoryDevices(categoryId),
      },
    });
  };

  const onAddDevice = device => {
    setModal({
      type: 'add-device',
      content: {
        idValue: categoryId,
        key: 'CATEGORY',
        addedDevices: categoryDevices,
        onSuccess: () => fetchCategoryDevices(categoryId),
      },
    });
  };

  return (
    <CategoryDetailsRightWrapper className="radius-4 card flex-1 flex-column">
      <div className="border-bottom details-header flex items-center px-6">
        <label className="bold-text font-24 flex-1">Devices</label>
        {hasPermission('CATEGORY_DEVICE_MANAGE') && (
          <Button
            label="Add new"
            icon={<PlusIcon className="primary-text" />}
            className="additional specified-width mr-4"
            width="110px"
            size="tiny"
            onClick={onAddDevice}
            disabled={loading}
          />
        )}
      </div>
      <div className="details-container pxy-6 flex-column flex-1 overflow-scroll">
        {loading ? (
          <div className="item flex items-center justify-center flex-1">
            <img alt="loading" height="40px" src={Loading} />
          </div>
        ) : categoryDevices.length > 0 ? (
          <div className="devices-wrapper">
            {categoryDevices.map(device => (
              <DeviceItem device={device} onRemoveDevice={onRemoveDevice} key={device.id} />
            ))}
          </div>
        ) : (
          <div className="flex-column items-center justify-center">
            <NoDeviceIcon />
            <label className="medium-text font-16 main-grey-text mt-6">
              This category currently belongs to all devices
            </label>
          </div>
        )}
      </div>
    </CategoryDetailsRightWrapper>
  );
};

export const CategoryDetailsRightWrapper = styled.div`
  .details-header {
    height: 72px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }

  .devices-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(240px, 1fr));
    gap: 24px;
  }
`;

export default CategoryDetailsRight;
