import classNames from 'classnames';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/error.svg';
import HidePassword from '../../assets/images/hide-password.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import Button from '../../components/Elements/button/button';
import PhoneInput from '../../components/common/phone-input';
import { countMatchesInRegex, onlyNumbers } from '../../helpers/utils';
import { useQuery } from '../../hooks/useQuery';
import { addToast } from '../../store/features/toastSlice';
import { activateUser, getPasswordRequirements, getRequiredField } from '../../store/features/userSlice';
import Loader from '../common/loader';
import CustomTooltip from '../common/tooltip-new';
import InviteExpired from './invite-expired';

const upperCaseRegex = /[A-Z]/g;
const lowerCaseRegex = /[a-z]/g;
const numberCaseRegex = /[0-9]/g;
const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;

const AccountActivation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = useQuery();
  const cleanedToken = query.get('token');
  const tokenData = cleanedToken ? JSON.parse(window.atob(cleanedToken.split('.')[1])) : {};
  const isTokenExpired = moment().unix() > tokenData?.exp;

  const [user, setUser] = useState({ employee_id: '', phone: '', country: '+44', password: '', confirmPassword: '' });
  const [error, setError] = useState({ employee_id: false });
  const [showPassword, setShowPassword] = useState({ newPassword: false, confirmPassword: false });
  const [loading, setLoading] = useState(false);
  const [fetchingRequiredFields, setFetchingRequiredFields] = useState(false);
  const [requiredFields, setRequiredFields] = useState({});
  const [errorText, setErrorText] = useState('');
  const [passwordRequirementError, setPasswordRequirementError] = useState({
    minumumPasswordLengthError: false,
    upperCaseError: false,
    lowerCaseError: false,
    numberError: false,
    specialCharacterError: false,
  });
  const [passwordRequiremets, setPasswordRequirements] = useState({
    min_length: 16,
    min_digits: 1,
    min_uppercase: 1,
    min_special: 1,
    min_lowercase: 1,
  });

  const fetchPasswordRequirements = async authenticationToken => {
    dispatch(getPasswordRequirements(authenticationToken))
      .then(data => setPasswordRequirements(data))
      .catch(() => setPasswordRequirements({}));
  };

  const checkPasswordRequirements = () => {
    const { password } = user;
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;

    const minumumPasswordLengthError = password ? password?.length < min_length : true;
    const upperCaseError = min_uppercase > 0 ? countMatchesInRegex(upperCaseRegex, password) < min_uppercase : false;
    const lowerCaseError = min_lowercase > 0 ? countMatchesInRegex(lowerCaseRegex, password) < min_lowercase : false;
    const numberError = min_digits > 0 ? countMatchesInRegex(numberCaseRegex, password) < min_digits : false;
    const specialCharacterError =
      min_special > 0 ? countMatchesInRegex(specialCharacterRegex, password) < min_special : false;

    setPasswordRequirementError({
      minumumPasswordLengthError,
      upperCaseError,
      lowerCaseError,
      numberError,
      specialCharacterError,
    });
    return minumumPasswordLengthError || upperCaseError || lowerCaseError || numberError || specialCharacterError;
  };

  const passwordRequirementTooltip = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;

    return (
      <div className="flex-column px-4 py-2">
        <div className="flex items-center">
          <ErrorIcon height={16} width={16} className="error-icon error-text" />
          <span className="medium-text font-12 ml-1">Password must:</span>
        </div>
        <div className="flex-column ml-2 mt-1">
          {passwordRequirementError.minumumPasswordLengthError && (
            <span className="regular-text main-grey-text font-12">Have {min_length} characters</span>
          )}
          {passwordRequirementError.upperCaseError && (
            <span className="regular-text main-grey-text font-12">Have {min_uppercase} upper case</span>
          )}
          {passwordRequirementError.lowerCaseError && (
            <span className="regular-text main-grey-text font-12">Have {min_lowercase} lower case</span>
          )}
          {passwordRequirementError.numberError && (
            <span className="regular-text main-grey-text font-12">Have {min_digits} number</span>
          )}
          {passwordRequirementError.specialCharacterError && (
            <span className="regular-text main-grey-text font-12">Have {min_special} special character</span>
          )}
        </div>
      </div>
    );
  };

  const onActivate = async () => {
    const { phone, country, password, confirmPassword, employee_id } = user;

    if (requiredFields.PHONE && !phone) {
      setError({ phone: true });
      setErrorText('Please enter phone number');
      return;
    }

    if (requiredFields.PHONE && phone && phone.length < 12) {
      setError({ phone: true });
      setErrorText('Phone number is not valid');
      return;
    }

    if (requiredFields.EMPLOYEE_ID && !employee_id) {
      setError({ employee_id: true });
      setErrorText('Please enter employee id');
      return;
    }

    if (checkPasswordRequirements()) {
      setError({ password: true });
      setErrorText('Password does not meet the minimum requirements');
      return;
    }

    if (password !== confirmPassword) {
      setError({ password: true });
      setErrorText('Passwords do not match');
      return;
    }

    setError({});
    setErrorText('');
    setLoading(true);
    const clearedPhoneNumber = phone?.replaceAll(' ', '')?.replaceAll('-', '');
    const userPhone = country + clearedPhoneNumber;

    const phoneRequest = requiredFields.PHONE ? { phone: userPhone } : {};
    const employeeIdRequest = requiredFields.EMPLOYEE_ID ? { employee_id: employee_id } : {};

    dispatch(activateUser({ token: cleanedToken, request: { password, ...phoneRequest, ...employeeIdRequest } }))
      .then(() => {
        dispatch(addToast({ error: false, text: 'User activated' }));
        navigate('/login');
      })
      .catch(error => {
        const errorText = error?.response?.data?.error_description || 'Error while activating user';
        setLoading(false);
        dispatch(addToast({ error: true, text: errorText }));
      });
  };

  useEffect(() => {
    if (cleanedToken && !isTokenExpired) {
      setFetchingRequiredFields(true);
      dispatch(getRequiredField(cleanedToken))
        .then(data => {
          const requiredFields = (data || [])
            .filter(item => item.is_required_in_invite)
            .reduce((acc, item) => {
              acc[item.field] = item;
              return acc;
            }, {});
          setRequiredFields(requiredFields);
          setFetchingRequiredFields(false);
        })
        .catch(() => {
          setRequiredFields({});
          setFetchingRequiredFields(false);
        });
      fetchPasswordRequirements(cleanedToken);
    }
  }, [cleanedToken, isTokenExpired]);

  return (
    <AccountActivationWrapper className="flex flex-column items-center radius-6 h-fit-content m-auto">
      {isTokenExpired ? (
        <InviteExpired />
      ) : fetchingRequiredFields ? (
        <Loader />
      ) : (
        <Fragment>
          <Logo />
          <label className="ramabhadra-regular-text font-24 mt-10 mb-4">{t('ACTIVATE_YOUR_ACCOUNT')}</label>
          <div className="flex-column row-gap-6 pt-6 w-full">
            {errorText && (
              <div className="flex items-center error-background password-error pxy-1 w-full">
                <ErrorIcon height={16} width={16} className="white-text" />
                <label className="medium-text white-text ml-2">{errorText}</label>
              </div>
            )}
            {requiredFields?.PHONE && (
              <div className="flex-column">
                <label className="regular-text font-12 main-grey-text mb-2">{t('PHONE_NUMBER')}</label>
                <div className="w-full input-container">
                  <PhoneInput
                    selectedCountry={user.country}
                    setSelectedCountry={country => setUser({ ...user, country, phone: '' })}
                    phone={user.phone}
                    setPhone={number => {
                      setUser({ ...user, phone: number });
                    }}
                    className={classNames('w-full', error.phone && 'error-phone')}
                  />
                </div>
              </div>
            )}
            {requiredFields?.EMPLOYEE_ID && (
              <div className="flex-column">
                <label className="regular-text font-12 main-grey-text mb-2">{t('EMPLOYEE_NUMBER')}</label>
                <input
                  autoComplete="turnoff"
                  className={classNames('input', error?.employee_id && 'error-border')}
                  onChange={e => {
                    if (onlyNumbers.test(e.target.value) || !e.target.value.trim()) {
                      setUser({ ...user, employee_id: e.target.value });
                      setError({ ...error, employee_id: false });
                    }
                  }}
                  placeholder={t('TYPE_EMPLOYEE_NUMBER')}
                  value={user?.employee_id}
                />
              </div>
            )}
            <div className="flex-column w-full">
              <label className="regular-text font-12 main-grey-text mb-2"> {t('PASSWORD')}</label>
              <div className="password-requirement-tooltip">
                <CustomTooltip
                  id="password-error-tooltip"
                  place={'right'}
                  isOpen={
                    error.password &&
                    (passwordRequirementError.minumumPasswordLengthError ||
                      passwordRequirementError.upperCaseError ||
                      passwordRequirementError.lowerCaseError ||
                      passwordRequirementError.numberError ||
                      passwordRequirementError.specialCharacterError)
                  }
                  content={passwordRequirementTooltip()}>
                  <div className="input-container relative">
                    <input
                      autoComplete="off"
                      placeholder={t('ENTER_YOUR_PASSWORD')}
                      value={user.password}
                      name="password"
                      className={classNames('input password-input w-full', error.password && 'error-border')}
                      onChange={e => {
                        setError({ ...error, password: false });
                        setErrorText('');
                        setUser({ ...user, password: e.target.value });
                      }}
                      type={showPassword.newPassword ? 'text' : 'password'}
                    />
                    <img
                      className="show-password"
                      src={showPassword.newPassword ? HidePassword : ShowPassword}
                      alt="show password"
                      onClick={() => setShowPassword({ ...showPassword, newPassword: !showPassword.newPassword })}
                    />
                  </div>
                </CustomTooltip>
              </div>
            </div>
            <div className="flex-column w-full">
              <label className="regular-text font-12 main-grey-text mb-2">{t('CONFIRM_PASSWORD')}</label>
              <div className="input-container relative">
                <input
                  autoComplete="off"
                  placeholder={t('CONFIRM_YOUR_PASSWORD')}
                  value={user.confirmPassword}
                  name="confirm-password"
                  className={classNames('input password-input w-full', error.password && 'error-border')}
                  onChange={e => {
                    setError({ ...error, password: false });
                    setErrorText('');
                    setUser({ ...user, confirmPassword: e.target.value });
                  }}
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                />
                <img
                  className="show-password"
                  src={showPassword.confirmPassword ? HidePassword : ShowPassword}
                  alt="show password"
                  onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })}
                />
              </div>
            </div>
          </div>
          <Button
            label={t('ACTIVATE')}
            className="mt-10 primary"
            size="medium"
            borderRadius="100px"
            onClick={onActivate}
            disabled={loading}
          />
        </Fragment>
      )}
    </AccountActivationWrapper>
  );
};

const AccountActivationWrapper = styled.div`
  width: 456px;
  padding: 40px 64px 64px 64px;
  background-color: ${({ theme }) => theme.colors.white};

  .input-container {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
  }

  .password-input {
    padding-right: 40px;
  }

  .show-password {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 16px;
    top: 12px;
    cursor: pointer;
  }

  .error-phone {
    .phone-input {
      border: 1px solid ${({ theme }) => theme.colors.primary} !important;
    }
  }

  .password-error {
    width: 100%;
    border-radius: 8px;

    img {
      width: 16px;
      height: 16px;
    }
  }
`;

export default AccountActivation;
