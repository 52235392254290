import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as NoDeviceIcon } from '../../assets/images/no-device-cross-icon.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { uppercase } from '../../helpers/utils';
import { useQuery } from '../../hooks/useQuery';
import { getUserDevices, setUserDevices } from '../../store/features/userManagementSlice';
import Button from '../Elements/button/button';
import Loader from '../common/loader';
import DeviceItem from './device-item';

const UserDetailsRight = () => {
  const dispatch = useDispatch();
  const { user_id } = useParams();

  let query = useQuery();
  const organization_id = query.get('organization_id');

  const { setModal, hasPermission } = useContext(OrganisationContext);

  const { userDevices } = useSelector(state => state.usersManagement);

  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 500);

  const groupedDevicesByOrganisation = userDevices.reduce((acc, device) => {
    const { organisation } = device;
    if (!organisation?.name) {
      if (acc['Default organisation']) {
        acc['Default organisation'].push(device);
      } else {
        acc['Default organisation'] = [device];
      }
      return acc;
    }
    if (acc[organisation.name]) {
      acc[organisation.name].push(device);
    } else {
      acc[organisation.name] = [device];
    }
    return acc;
  }, {});

  const fetchUserDevices = user_id => {
    setLoading(true);
    dispatch(
      getUserDevices({
        user_id: user_id,
        params: { search: debouncedSearch },
      }),
    )
      .catch(error => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (user_id) {
      fetchUserDevices(user_id);
    }

    return () => {
      dispatch(setUserDevices([]));
    };
  }, [user_id, debouncedSearch]);

  const onRemoveDevice = device => {
    setModal({
      type: 'remove-device',
      content: {
        device: device,
        idValue: user_id,
        key: 'USER',
        onSuccess: () => fetchUserDevices(user_id),
      },
    });
  };

  const onAddDevice = () => {
    setModal({
      type: 'add-device',
      content: {
        idValue: user_id,
        key: 'USER',
        addedDevices: userDevices,
        fromUserDetails: true,
        organization_id: organization_id,
        onSuccess: () => fetchUserDevices(user_id),
      },
    });
  };

  return (
    <UserDetailsRightWrapper className="radius-4 card flex-1 flex-column">
      {hasPermission('USER_DEVICES_VIEW') ? (
        <Fragment>
          <div className="border-bottom details-header flex items-center px-6">
            <label className="bold-text font-24 flex-1">Devices</label>
            {/* <InputSearch className="mr-4 device-search" onChange={setSearch} value={search} placeholder="Search" /> */}
            {hasPermission('USER_DEVICES_MANAGE') && (
              <Button
                label="Add new"
                icon={<PlusIcon className="primary-text" />}
                className="additional specified-width mr-4"
                width="110px"
                size="tiny"
                onClick={onAddDevice}
                disabled={loading}
              />
            )}
          </div>
          <div className="details-container pxy-6 flex-column flex-1 overflow-scroll row-gap-6">
            {loading ? (
              <Loader height={40} width={40} />
            ) : Object.keys(groupedDevicesByOrganisation).length > 0 ? (
              Object.entries(groupedDevicesByOrganisation).map(([key, value]) => (
                <div className="flex-column" key={key}>
                  <label className="semibold-text main-grey-text mb-4">{uppercase(key)}</label>
                  <div className="devices-wrapper">
                    {value.map(device => (
                      <DeviceItem device={device} onRemoveDevice={onRemoveDevice} key={device.id} />
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex-column items-center justify-center">
                <NoDeviceIcon />
                <label className="medium-text font-16 main-grey-text mt-6">
                  This user currently has no devices assigned
                </label>
              </div>
            )}
          </div>
        </Fragment>
      ) : (
        <div className="flex-column items-center justify-center flex-1">
          <NoDeviceIcon />
          <label className="medium-text font-16 main-grey-text mt-6">
            {"You don't have permission to view devices"}
          </label>
        </div>
      )}
    </UserDetailsRightWrapper>
  );
};

export const UserDetailsRightWrapper = styled.div`
  .details-header {
    height: 72px;
  }

  .icon-container {
    background-color: ${({ theme }) => theme.colors.additional};
  }

  .devices-wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(240px, 1fr));
    gap: 24px;
  }

  .device-search {
    .input {
      height: 32px;
    }
  }
`;

export default UserDetailsRight;
