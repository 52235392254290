export const TRANSLATIONS_EN_US = {
  EMAIL_ADDRESS: 'Email address',
  PASSWORD: 'Password',
  CONFIRM_PASSWORD: 'Confirm password',
  ENTER_PASSWORD: 'Enter password',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot my password',
  INCORRECT_EMAIL_PASSWORD: 'Incorrect email or password',
  REQUEST_TIMEOUT: 'Request Cancelled due to timeout',
  EMAIL_TO_RECEIVE_CODE: 'Enter your email to receive the security code',
  ENTER_EMAIL_ADDRESS: 'Enter Email address',
  WE_SENT_YOU_EMAIL: 'We sent you an email',
  SEND: 'Send',
  SECURITY_REASON_DETAIL_TEXT:
    'For added security, we need to further verify your account. Please, enter security code from email here',
  INCORRECT_OTP_CODE_ERROR: 'Incorrect code - please check your code is correct and try again',
  VERIFY: 'Verify',
  SUBMIT: 'Submit',
  RESEND_SECURE_CODE: 'Resend secure code',
  RESET_YOUR_PASSWORD: 'Reset your password',
  NEW_PASSWORD: 'New password',
  ENTER_NEW_PASSWORD: 'Enter new password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  ENTER_YOUR_PASSWORD: 'Enter your password',
  CONFIRM_YOUR_PASSWORD: 'Confirm your password',
  SET_NEW_PASSWORD: 'Set new password',
  SEND_CODE_BY_SMS: 'Send code by SMS',
  SEND_CODE_BY_EMAIL: 'Send code by email',
  SAME_EMAIL_ADDRESS_DETAIL: 'Please ensure you enter the same email address registered with your account',
  PHONE_NUMBER_TO_RECEIVE_CODE: 'Enter your phone number to receive the security code',
  SAME_PHONE_NUMBER_DETAIL: 'Please ensure you enter the same phone number registered with your account',
  PHONE_NUMBER: 'Phone number',
  WE_SENT_YOU_SMS: 'We sent you an SMS',
  CHECK_SMS_CODE: 'Check your SMS and enter your security code below',
  CHECK_EMAIL_CODE: 'Check your email and enter your security code below',
  MINIMUM_PASSWORD_REQUIREMENT:
    'Minimum password 16 characters, at least one upper case, one number, and one special character',
  ADD_NEW: 'Add new',
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  EDIT: 'Edit',
  DELETE: 'Delete',
  NAME: 'Name',
  DESCRIPTION: 'Description',
  PRODUCT_NOTES: 'Product notes',
  CATEGORY_NOTES: 'Category notes',
  PRIZE_DRAW_CODE: 'Prize draw code',
  STATUS: 'Status',
  EXPORT: 'Export',
  RESULTS: 'results',
  MENU: 'Menu',
  DETAILS: 'Details',
  SETTINGS: 'Settings',
  SECURITY: 'Security',
  CONFIGURE_YOUR_SECURITY: 'Configure your security settings',
  STORAGE: 'Storage',
  STORAGE_RULES: 'Storage rules',
  CREATE_STORAGE_SETTINGS: 'Create Storage settings',
  STORAGE_RULE_GROUPS: 'Storage Rule Groups',
  STORAGE_RULE_GROUP: 'Storage Rule Group',
  USERS: 'Users',
  MANAGE_CURRENT_AND_INVITE_USERS: 'Manage current and invite new users',
  TEAMS: 'Teams',
  MANAGE_AND_INVITE_NEW_TEAMS: 'Manage current and create new teams',
  USER_MANAGEMENT: 'User Management',
  MANAGE_USER_ROLES_AND_TEAMS: 'Manage users, roles and teams',
  NO_DATA: 'No data',
  INVITE_USER: 'Invite User',
  NO_DATA: 'No data',
  NO_RESULT: 'No results found',
  TYPE_USER_EMAIL: 'Type user’s email',
  TYPE_USER_NAME: 'Full user’s name',
  EMAIL: 'Email',
  PHONE: 'Phone',
  PHONE_OPTIONAL: 'Phone (optional)',
  USER_ROLE: 'User’s role',
  TYPE_USER_PHONENUMBER: '"Type user’s phone number"',
  EMPLOYEE_NUMBER: 'Employee number',
  BADGE_NUMBER: 'Badge number',
  BADGE_NUMBER_OPTINAL: 'Badge number (optional)',
  TYPE_EMPLOYEE_NUMBER: "Type employee's number",
  TYPE_BADGE_NUMBER: 'Type badge number',
  CANCEL: 'Cancel',
  SEND_INVITE: 'Send invite',
  NO_RESULT_FOUND: 'There aren’t any results for this query.',
  REMOVE: 'Remove',
  PLATFORMS: 'Platforms',
  AUTOMATIONS: 'Automations',
  AUTOMATION_DETAILS: 'Automation details',
  OWNER: 'Owner',
  USAGE: 'Usage',
  USE: 'Use',
  ADD: 'Add',
  ENTER_DESCRIPTION: 'Enter description',
  ADDRESS: 'Address',
  USER_INFO: 'User info',
  NEXT: 'Next',
  PREVIOUS: 'Previous',
  ADDRESS_LINE_1: 'Address line 1',
  ADDRESS_LINE_2: 'Address line 2',
  ENTER_ADDRESS: 'Enter address',
  CITY: 'City',
  ENTER_CITY: 'Enter city',
  STATE: 'State',
  COUNTY: 'County',
  POST_CODE: 'Post code',
  ZIP_CODE: 'Zip code',
  SELECT_STATE: 'Select State',
  ENTER_ZIPCODE: 'Enter zip code',
  BACK: 'Back',
  CREATE: 'Create',
  ENTER_FULL_NAME: 'Enter full name',
  LABEL: 'Label',
  CLIENT: 'Client',
  SAVE: 'Save',
  NO: 'No',
  enter_type: 'Enter {{type}}',
  select_type: 'Select {{type}}',
  category: {
    new: 'New category',
    edit: 'Edit category',
  },
  number_of_type: 'Number of {{type}}',
  new_type: 'New {{type}}',
  edit_type: 'Edit {{type}}',
  ACTIVATE_YOUR_ACCOUNT: 'Activate your account',
  ACTIVATE: 'Activate',
  NO_STORAGE_LIST: 'There are no storage here yet',
  STORAGE_UPDATE: 'Storage update successfully',
  ERROR_STORAGE_UPDATE: 'Error while updating storage',
  STORAGE_ADD: 'Storage add successfully',
  ERROR_STORAGE_ADD: 'Error while adding storage',
  STORAGE_RULES_UPDATE: 'Storage rules update successfully',
  ERROR_STORAGE_RULES_UPDATE: 'Error while updating storage rules',
  ERROR_STORAGE_RULES_FETCHING: 'Error while fetching storage rules list',
  STORAGE_RULES_ADD: 'Storage rules add successfully',
  ERROR_STORAGE_RULES_ADD: 'Error while adding storage rules',
  ERROR_STORAGE_RULES_GROUP: 'Error while changing storage rules group',
  SUCCESSFULLY_CHANGE_GROUP: 'Successfully change group',
  STORAGE_RULES_DELETE: 'Storage rules deleted',
  ERROR_STORAGE_RULES_DELETE: 'Error while deleting storage rules',
  STORAGE_DELETE: 'Storage deleted',
  STORAGE_DELETE_GROUP: 'Storage group deleted',
  ERROR_STORAGE_DELETE: 'Error while deleting storage',
  ERROR_STORAGE_DELETE_GROUP: 'Error while deleting storage group',
  SUBSCRIPTION_ID: 'Subscription ID',
  BILLING_PREIOD: 'Billing period',
  CREATE_ON: 'Created on',
  REQUIRE_PROFILE_PINS: 'Require profile pins',
  LOCAL_SETTINGS: 'Local Settings',
  CHANGE_GROUP: 'Change Group',
  NO_STORAGE_RULES: 'There are no storage rules available for this group id',
  ARE_YOU_SURE_TO_DELETE_STORAGE_GROUP: 'Are you sure you want to delete this storage rule group?',
  NOT_WILL_BE_UNDU_ACTION: 'You will not be able to undo this action',
  DEVICE_INFO: 'Device info',
  ADD_DEFAULT_STORAGE_RULES: 'Do you want to add default storage rules to this device?',
  STORAGE_GROUP_ADD: 'Storage group add successfully',
  ERROR_STORAGE_GROUP_ADD: 'Error while adding Storage group',
  STORAGE_GROUP_UPDATE: 'Storage group update successfully',
  ERROR_STORAGE_GROUP_UPDATE: 'Error while updating Storage group',
  DEVICE_CODE: 'Device code',
};
