import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '../../hooks/useQuery';
import { getUserDetails, setUserDetails } from '../../store/features/userManagementSlice';
import Loader from '../common/loader';
import UserDetailsLeft from './details-left';
import UserDetailsRight from './details-right';

const UserDetails = ({}) => {
  const dispatch = useDispatch();
  const { user_id } = useParams();

  let query = useQuery();
  const organization_id = query.get('organization_id');

  const [loading, setLoading] = useState(true);

  const fetchUserDetails = user_id => {
    setLoading(true);
    dispatch(
      getUserDetails({
        user_id: user_id,
        params: {
          organization_id: organization_id || null,
        },
      }),
    )
      .catch(error => {})
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (user_id) {
      fetchUserDetails(user_id);
    }

    return () => {
      dispatch(setUserDetails({}));
    };
  }, [user_id]);

  return (
    <UserDetailsWrapper className="flex-column flex-1 mt-6 mx-2 overflow-hidden">
      {loading ? (
        <Loader height={40} width={40} />
      ) : (
        <div className="flex flex-1 col-gap-6 h-full">
          <UserDetailsLeft fetchUserDetails={fetchUserDetails} />
          <UserDetailsRight />
        </div>
      )}
    </UserDetailsWrapper>
  );
};

export const UserDetailsWrapper = styled.div``;

export default UserDetails;
