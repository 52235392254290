import moment from 'moment/moment';
import { getItemFromLocalStorage } from './localstorage';

export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;
export const onlyNumbers = /^\d+$/;

export const capitalize = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatText = (text, replaceWith = '-') => {
  if (!text) return '';
  return text.toLowerCase().replaceAll('_', replaceWith);
};

export const uppercase = str => {
  if (!str) return '';
  return str.toUpperCase();
};

export const lowercase = str => {
  if (!str) return '';
  return str.toLowerCase();
};

export const getErrorDescription = (error, defaultErrorText = '') => {
  if (!error) return '';
  return error?.response?.data?.error_description || defaultErrorText;
};

export const getUsersPermissions = () => {
  const user = getItemFromLocalStorage('user');
  return user?.api_tokens?.permissions || [];
};

export const countMatchesInRegex = (regex, str) => {
  return ((str || '').match(regex) || []).length;
};

export const getUserInitials = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}${userName?.[1]?.charAt(0)?.toUpperCase() || ''}`
    : forename || surname
    ? `${forename?.charAt(0)?.toUpperCase() || ''}${surname?.charAt(0)?.toUpperCase() || ''}`
    : '';
};

export const getRecurringPeriodName = period => {
  switch (period) {
    case 'year':
    case 'YEAR':
      return 'Yearly';
    case 'month':
    case 'MONTH':
      return 'Monthly';
    case 'week':
    case 'WEEK':
      return 'Weekly';
    default:
      return '';
  }
};

export const createFilterUrl = filters => {
  return filters
    ?.filter(item => item.child)
    .reduce((acc, curr) => {
      const childComponent = curr.childComponent;
      const valueComponent = curr.value;
      if (childComponent === 'DATE_RANGE') {
        acc.push({ ...curr, param: `${curr.param}.min`, child: { value: curr.child?.min } });
        acc.push({ ...curr, param: `${curr.param}.max`, child: { value: curr.child?.max } });
      } else if (childComponent === 'MULTI_DROPDOWN') {
        const valuesWithComma = curr.child?.map(value => value.value).join(',') || '';
        acc.push({ ...curr, param: curr.param, child: { value: valuesWithComma } });
      } else if (valueComponent === 'DEVICE_VISIBLE') {
        acc.push(curr);
        acc.push({ ...curr, param: 'use_device_categories', child: { value: true } });
      } else {
        acc.push(curr);
      }
      return acc;
    }, [])
    .reduce((url, curr) => {
      let currFilter = url[curr.param];
      if (!currFilter) {
        currFilter = [];
      }
      currFilter.push(curr?.child?.value);
      url[curr.param] = currFilter;
      return url;
    }, {});
};

export const getFormattedDate = (dateInUnix, format = 'DD/MM/YYYY') => {
  return moment(dateInUnix * 1000).format(format);
};

export const getDateDifference = (date, compareWith = null, unit = 'days') => {
  const compareWithMoment = compareWith ? moment(compareWith * 1000) : moment();

  return date ? compareWithMoment.utc(false).diff(moment(date * 1000).utc(false), unit) : 0;
};

export const getDateMoment = dateInUnix => {
  return moment(dateInUnix * 1000).utc();
};

export const isEditorValueEmpty = value => {
  return value.replace(/(<([^>]+)>)/gi, '').trim().length === 0;
};

export const getContactValueByType = (contacts, type) => {
  const contactsByType = contacts?.filter(c => c.contact_type === type) || [];
  const primaryContact = contactsByType.find(c => c.is_primary);
  return primaryContact || contactsByType?.[0] || {};
};

export const getOrganizationByCategoryTag = (organizations = [], category_tag = '') => {
  const organizationByCategoryTag = organizations?.find(org => org.category?.tag?.tag === category_tag) || {};
  return organizationByCategoryTag || {};
};

export const handleCopyClick = async url => {
  try {
    await navigator.clipboard.writeText(url);
    return true;
  } catch (error) {
    console.error('Failed to copy:', error);
    return false;
  }
};

export const getErrorFieldJoined = (errorObject, getValue = () => {}) => {
  return Object.entries(errorObject)
    .filter(([_, value]) => value)
    .map(([key, value]) => getValue(key, value))
    .join(', ');
};

export const checkRoleTier = (currentUserRole, otherUserRole) => {
  const { tier, is_superuser } = currentUserRole || {};
  const { tier: otherTier } = otherUserRole || {};

  if (is_superuser) return true;
  if (!tier || !otherTier) return false;
  return tier < otherTier;
};

export const isSuperUser = user => {
  const { organizations } = user || {};
  const { role } = organizations?.[0] || {};
  const { is_superuser } = role || {};
  return is_superuser;
};

export const generateRandomPassword = (length = 12) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

export const isUserSassOperator = () => {
  const user = getItemFromLocalStorage('user');
  const userOrganization = user?.organizations?.[0];
  return userOrganization?.organization_type === 'SAAS_OPERATOR';
};
