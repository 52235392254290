import { createSlice } from '@reduxjs/toolkit';
import apiV3 from '../services/apiV3';
import api from '../services/index';

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categoryList: {},
    categoryDetails: {},
    categoryDevices: [],
  },
  reducers: {
    setCategoryList: (state, { payload }) => {
      const { data, merge } = payload;
      if (merge) {
        state.categoryList = { ...data, content: [...state.categoryList.content, ...data.content] };
      } else {
        state.categoryList = data;
      }
    },
    setCategoryDetails: (state, { payload }) => {
      state.categoryDetails = payload;
    },
    setCategoryDevices: (state, { payload }) => {
      state.categoryDevices = payload;
    },
  },
});

export const getCategoryList = payload => async dispatch => {
  try {
    const { params, merge, forFetchOnly = false } = payload;
    const { data } = await apiV3.get(`/api/categories`, {
      params: params,
      paramsSerializer: {
        indexes: null,
      },
    });
    if (!forFetchOnly) {
      dispatch(setCategoryList({ data, merge }));
    }
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCategoryDetails = payload => async dispatch => {
  try {
    const { categoryId } = payload;
    const { data } = await apiV3.get(`/api/categories/${categoryId}`);
    dispatch(setCategoryDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getCategoryDevices = payload => async dispatch => {
  try {
    const { categoryId } = payload;
    const { data } = await apiV3.get(`/api/categories/${categoryId}/devices`);
    dispatch(setCategoryDevices(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const removeCategoryDevice = payload => async dispatch => {
  try {
    const { categoryId, deviceId } = payload;
    const { data } = await apiV3.delete(`/api/categories/${categoryId}/devices/${deviceId}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createCategory = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await apiV3.post(`/api/categories`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDeviceList = payload => async dispatch => {
  try {
    const { params } = payload;
    const { data } = await api.get(`/devices`, { params: params });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addDeviceToCategory = payload => async dispatch => {
  try {
    const { category_id, request } = payload;
    const { data } = await apiV3.post(`/api/categories/${category_id}/devices`, request);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateCategory = payload => async dispatch => {
  try {
    const { category_id, request } = payload;
    const { data } = await apiV3.put(`/api/categories/${category_id}`, request);
    dispatch(setCategoryDetails(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const { setCategoryList, setCategoryDetails, setCategoryDevices } = categorySlice.actions;
export default categorySlice.reducer;
