import React, { Fragment, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';
import OrganizationFilter from '../../components/common/organization-filter';
import DeviceDetails from '../../components/device-details';
import DevicesList from '../../components/device-list';
import PageHeader from '../../components/page-header';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';

const Devices = () => {
  const navigate = useNavigate();
  const { setModal } = useContext(OrganisationContext);

  const { deviceDetails } = useSelector(state => state.device);

  const [searchText, setSearchText] = useState('');
  const debouncedSearch = useDebounce(searchText, 500);

  const [organizationFilter, setOrganizationFilter] = useState(null);

  const onAddClick = () => {
    setModal({
      type: '',
      content: {
        onSuccess: data => navigate(`device-details-info/${data.id}`),
      },
    });
  };

  return (
    <DevicesWrapper className="flex-column w-full">
      <Routes>
        <Route
          path="device-details-info/:device_id"
          element={
            <Fragment>
              <PageHeader
                names={[
                  { text: 'Devices', path: '/devices' },
                  ...(deviceDetails?.name ? [{ text: deviceDetails?.name, onClick: () => {} }] : []),
                ]}
                showBackButton
                onBackClick={() => navigate('/devices')}
              />
              <DeviceDetails />
            </Fragment>
          }
        />
        <Route
          element={
            <Fragment>
              <PageHeader
                className={organizationFilter?.length ? 'items-start' : ''}
                title="Devices"
                showAddNewBtn={false}
                onAddClick={onAddClick}
                showSearch={true}
                addBtnPermission="DEVICES_VIEW"
                searchText={searchText}
                addBtnClassName="additional"
                onSearchChange={setSearchText}
                Icon={PlusIcon}
                iconColor="#08B2E4"
                showCustomSearchableDropdownFiled={true}
                customSearchableDropdownFiled={() => (
                  <OrganizationFilter
                    organizationFilter={organizationFilter}
                    setOrganizationFilter={setOrganizationFilter}
                  />
                )}
                organizationFilter={organizationFilter}
              />
              <DevicesList debouncedSearch={debouncedSearch} organizationFilter={organizationFilter} />
            </Fragment>
          }
          path="/*"
        />
      </Routes>
    </DevicesWrapper>
  );
};

const DevicesWrapper = styled.div``;

export default Devices;
