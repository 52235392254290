import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AccountsIcon } from '../../assets/icons/menu/account.svg';
import { ReactComponent as CategoriesIcon } from '../../assets/icons/menu/categories.svg';
import { ReactComponent as DeviceIcon } from '../../assets/icons/menu/devices.svg';
import { ReactComponent as IntegrationsIcon } from '../../assets/icons/menu/integrations.svg';
import { ReactComponent as PlatformIcon } from '../../assets/icons/menu/platform.svg';
import { ReactComponent as ProductsIcon } from '../../assets/icons/menu/products.svg';
import { ReactComponent as ReportIcon } from '../../assets/icons/menu/reports.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/menu/settings.svg';
import Sidebar from '../../components/sidebar/sidebar';
import { OrganisationContext } from '../../context/organisationContext';
import { getUsersPermissions } from '../../helpers/utils';
import Modal from '../../popup';
import history from '../../routes/history';
import { getAccountCategoryTagInfo, getAccountDetails } from '../../store/features/accountsSlice';
import { addToast } from '../../store/features/toastSlice';
import { MainWrapper } from '../../styles/pages/main.styled';
import { ROUTE_PATHS } from './route-paths';

const Main = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toast);
  const { user, userOrganization } = useSelector(state => state.user);

  const [selectedPath, setSelectedPath] = useState(location.pathname);
  const [modal, setModal] = useState({
    type: null,
    content: {},
  });
  const [organizationParentChildIds, setOrganizationParentChildIds] = useState([]);
  const [organizationParentChilds, setOrganizationParentChilds] = useState([]);
  const [organizationParentChildData, setOrganizationParentChildData] = useState({ organization: {}, networks: [] });
  const [loadingOrganizationParentChilds, setLoadingOrganizationParentChilds] = useState(true);

  const permissions = getUsersPermissions();

  const hasPermission = permission => {
    if (!permission) {
      return true;
    }
    return permissions.includes(permission);
  };

  const hasMultiplePermission = permissionList => {
    return (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    );
  };

  const checkPermission = (action, permission) => {
    if (!permission || permissions.includes(permission)) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
        );
  };

  const fetchOrgnizationInformation = async () => {
    const account_id = userOrganization?.id;
    try {
      setLoadingOrganizationParentChilds(true);
      const organizationData = await dispatch(
        getAccountDetails({
          organizationId: account_id,
          isFetchOnly: true,
        }),
      );
      const { content: networks } = await dispatch(
        getAccountCategoryTagInfo({
          organizationId: account_id,
        }),
      );
      const { parent_organizations } = organizationData;
      const parent_organization_ids = parent_organizations?.map(p => p.id) || [];
      const child_ids = networks?.map(n => n.id) || [];
      const updatedParentOrganizations = parent_organizations.map(org => ({ ...org, isParent: true }));
      setOrganizationParentChildIds([account_id, ...parent_organization_ids, ...child_ids]);
      setOrganizationParentChilds([...updatedParentOrganizations, ...networks]);
      setOrganizationParentChildData({ organization: organizationData, networks });
      setLoadingOrganizationParentChilds(false);
    } catch (error) {
      console.log(error);
      setOrganizationParentChildIds([]);
      setOrganizationParentChilds([]);
      setOrganizationParentChildData({ organization: {}, networks: [] });
      setLoadingOrganizationParentChilds(false);
    }
  };

  useEffect(() => {
    fetchOrgnizationInformation();

    return () => {
      setOrganizationParentChildIds([]);
      setOrganizationParentChilds([]);
      setOrganizationParentChildData({ organization: {}, networks: [] });
    };
  }, [userOrganization]);

  const items = useMemo(
    () => [
      {
        id: 1,
        icon: ReportIcon,
        iconSelected: ReportIcon,
        text: 'Reports',
        redirect: '/reports',
        selected: location.pathname === '/reports',
        subitems: [],
        // hasPermission: hasMultiplePermission(['REPORTS_VIEW']),
        hasPermission: true,
      },
      {
        id: 2,
        icon: AccountsIcon,
        iconSelected: AccountsIcon,
        text: 'Accounts',
        redirect: '/accounts',
        selected: location.pathname === '/accounts',
        subitems: [],
        hasPermission:
          userOrganization.organization_type === 'SAAS_OPERATOR' && hasMultiplePermission(['NETWORK_LIST_VIEW']),
      },
      {
        id: 3,
        icon: ProductsIcon,
        iconSelected: ProductsIcon,
        text: 'Products',
        redirect: '/products',
        selected: location.pathname === '/products',
        subitems: [],
        hasPermission:
          userOrganization.organization_type !== 'SAAS_OPERATOR' && hasMultiplePermission(['PRODUCTS_VIEW']),
      },
      {
        id: 4,
        icon: CategoriesIcon,
        iconSelected: CategoriesIcon,
        text: 'Categories',
        redirect: '/categories',
        selected: location.pathname === '/categories',
        subitems: [],
        hasPermission:
          userOrganization.organization_type !== 'SAAS_OPERATOR' && hasMultiplePermission(['CATEGORIES_VIEW']),
      },
      // {
      //   id: 'STORAGE',
      //   icon: StorageIcon,
      //   iconSelected: StorageIcon,
      //   text: 'Storages',
      //   redirect: '/storages',
      //   selected: location.pathname === '/storages',
      //   subitems: [],
      //   hasPermission:
      //     userOrganization.organization_type !== 'SAAS_OPERATOR' && hasMultiplePermission(['PRODUCTS_VIEW']),
      // },
      {
        id: 'DEVICES',
        icon: DeviceIcon,
        iconSelected: DeviceIcon,
        text: 'Devices',
        redirect: '/devices',
        selected: location.pathname === '/devices',
        subitems: [],
        hasPermission:
          userOrganization.organization_type !== 'SAAS_OPERATOR' && hasMultiplePermission(['DEVICES_VIEW']),
      },
      {
        id: 5,
        icon: PlatformIcon,
        iconSelected: PlatformIcon,
        text: 'Platform',
        redirect: '/platform',
        selected: location.pathname === '/platform',
        hasPermission:
          userOrganization.organization_type === 'SAAS_OPERATOR' && hasMultiplePermission(['INTEGRATIONS_VIEW']),
        subitems: [
          {
            id: 0,
            icon: IntegrationsIcon,
            iconSelected: IntegrationsIcon,
            text: 'Integrations',
            redirect: '/platforms/integrations',
            selected: location.pathname.startsWith('/platforms/integrations'),
            hasPermission:
              userOrganization.organization_type === 'SAAS_OPERATOR' && hasMultiplePermission(['INTEGRATIONS_VIEW']),
          },
          // {
          //   id: 1,
          //   icon: AutomationsIcon,
          //   iconSelected: AutomationsIcon,
          //   text: 'Automations',
          //   redirect: '/platforms/automations',
          //   selected: location.pathname.startsWith('/platforms/automations'),
          //   hasPermission: true,
          // },
        ],
      },
      {
        id: 6,
        icon: SettingsIcon,
        iconSelected: SettingsIcon,
        text: 'Settings',
        redirect: '/settings',
        selected: location.pathname === '/settings',
        subitems: [],
        hasPermission: true,
      },
    ],
    [location.pathname, userOrganization],
  );

  const [menuItems, setMenuItems] = useState(
    items
      .filter(item => item.hasPermission)
      .map(item => ({ ...item, subitems: item.subitems ? item.subitems.filter(si => si.hasPermission) : [] })),
  );

  useEffect(() => {
    setMenuItems(
      items
        .filter(item => item.hasPermission)
        .map(item => ({ ...item, subitems: item.subitems ? item.subitems.filter(si => si.hasPermission) : [] })),
    );
  }, [user.isAccessAccount]);

  const onItemClick = (itemId, subitemId) => {
    const item = menuItems.find(item => item.id === itemId);
    if (item) {
      if (item.subitems.length > 0) {
        if (subitemId !== 0 && !subitemId)
          setMenuItems(
            menuItems.map(menuItem => (menuItem.id === itemId ? { ...menuItem, open: !menuItem.open } : menuItem)),
          );
        else {
          navigate(item.subitems.find(subitem => subitem.id === subitemId).redirect);
        }
      } else {
        navigate(item.redirect);
      }
    }
  };

  useEffect(() => {
    if (selectedPath)
      setMenuItems(
        menuItems.map(menuItem =>
          selectedPath.startsWith(menuItem.redirect)
            ? {
                ...menuItem,
                open: menuItem.subitems.length > 0,
                selected: true,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  selected: selectedPath.startsWith(subitem.redirect),
                })),
              }
            : {
                ...menuItem,
                selected: false,
                open: false,
                subitems: menuItem.subitems.map(subitem => ({
                  ...subitem,
                  selected: false,
                })),
              },
        ),
      );
  }, [selectedPath]);

  useEffect(() => {
    if (history.action === 'POP' || history.action === 'REPLACE' || history.action === 'PUSH') {
      setSelectedPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    navigator.serviceWorker.getRegistrations().then(regs => regs.forEach(reg => reg.update()));
  }, [location]);

  const checkMultiplePermission = (action, permissionList) => {
    if (
      !permissionList ||
      (permissionList.length > 0 && permissionList.find(permission => permissions.includes(permission)))
    ) {
      if (action) action();
    } else
      !toasts.find(t => t.id === 'PERMISSION_ERROR') &&
        dispatch(
          addToast({
            id: 'PERMISSION_ERROR',
            error: true,
            text: 'You do not have permission to perform this action',
          }),
        );
  };

  return (
    <MainWrapper className="pl-6 pr-4 py-6">
      <OrganisationContext.Provider
        value={{
          modal,
          setModal,
          checkPermission,
          hasPermission,
          hasMultiplePermission,
          checkMultiplePermission,
          organizationParentChildIds,
          organizationParentChilds,
          loadingOrganizationParentChilds,
          organizationParentChildData,
        }}>
        <Sidebar menuItems={menuItems} onItemClick={onItemClick} selectedPath={selectedPath} user={user} />
        <div className="main">
          <div className="content pl-4">
            <Routes>
              {ROUTE_PATHS.filter(path => hasMultiplePermission(path.permission)).map(path => (
                <Route
                  key={path.id}
                  element={<path.Component {...path.params} path={path.path} {...path.actions} />}
                  path={path.path}
                />
              ))}
              <Route
                element={
                  <Navigate
                    replace
                    to={
                      ROUTE_PATHS.find(path => hasMultiplePermission(path.permission))
                        ? ROUTE_PATHS.find(path => hasMultiplePermission(path.permission)).path
                        : '/profile'
                    }
                  />
                }
                path="*"
              />
            </Routes>
          </div>
        </div>
        <Modal />
      </OrganisationContext.Provider>
    </MainWrapper>
  );
};

export default Main;
