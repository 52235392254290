import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Loading from '../../assets/images/loading.svg';
import { OrganisationContext } from '../../context/organisationContext';
import useDebounce from '../../helpers/useDebounceHook';
import { uppercase } from '../../helpers/utils';
import { getAccountCategoryTagInfo, setAccountSearchFields } from '../../store/features/accountsSlice';
import InfiniteScrollComponent from '../common/infinite-scroll';
import BrandListItem from './brand-list-item';
import CategoryTagDetails from './category-tag-detail';

const accountsTableHeaders = [
  { name: 'NAME', key: 'NAME' },
  { name: 'ADDRESS', key: 'ADDRESS' },
  { name: 'PHONE', key: 'PHONE' },
  { name: 'EMAIL', key: 'EMAIL' },
];

const BrandDetails = ({
  fromMyAccount = false,
  organizationId = null,
  organizationName = '',
  setBrandIds = () => {},
  setBrandOrVenueLoading = () => {},
}) => {
  const dispatch = useDispatch();
  const pageRef = useRef(null);

  const { account_id } = useParams();

  const { setModal } = useContext(OrganisationContext);

  const { accountSearchFields } = useSelector(state => state.accounts);

  const [brandList, setBrandList] = useState([]);
  const [searchText, setSearchText] = useState(accountSearchFields.brand || '');
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const debouncedSearch = useDebounce(searchText, 500);

  useEffect(() => {
    dispatch(setAccountSearchFields({ ...accountSearchFields, brand: searchText }));
  }, [searchText]);

  const fetchAccountCategoryTagInfo = (account_id, category_tag, page, merge, debouncedSearch, showLoading = true) => {
    pageRef.current = page;
    if (showLoading && !merge) {
      setLoading(true);
      setBrandOrVenueLoading(prev => ({ ...prev, brandLoading: true }));
    }
    dispatch(
      getAccountCategoryTagInfo({
        organizationId: account_id,
        params: { page: page, size: 20, category_tag, search: debouncedSearch },
      }),
    )
      .then(data => {
        const { last, content } = data;
        const updatedBrands = merge ? [...brandList.brands, ...content] : [...content];
        const brandIds = updatedBrands.map(brand => brand.id);
        setBrandList({ last, brands: [...updatedBrands] });
        setBrandIds(brandIds);
      })
      .catch(error => {
        console.error('error', error);
      })
      .finally(() => {
        setLoading(false);
        setBrandOrVenueLoading(prev => ({ ...prev, brandLoading: false }));
      });
  };

  const fetchMoreData = () => {
    fetchAccountCategoryTagInfo(account_id, 'BRAND', pageRef.current + 1, true, debouncedSearch, false);
  };

  useEffect(() => {
    const accountId = fromMyAccount ? organizationId : account_id;
    if (accountId) {
      fetchAccountCategoryTagInfo(accountId, 'BRAND', 0, false, debouncedSearch, true);
    }
  }, [organizationId, account_id, debouncedSearch]);

  const onAddClick = () => {
    const accountId = fromMyAccount ? organizationId : account_id;
    setModal({
      type: 'add-account',
      content: {
        fromMyAccount: fromMyAccount,
        organizationId: accountId,
        organizationName: organizationName,
        category_tag: 'BRAND',
        onSuccess: () => fetchAccountCategoryTagInfo(accountId, 'BRAND', 0, false, debouncedSearch, true),
      },
    });
  };

  const renderTableHeader = headers => {
    return (
      <div className="border-top border-bottom header-container">
        {headers.map(header => (
          <label key={header.key} className="flex items-center bold-text px-5 font-12">
            {uppercase(header.name)}
          </label>
        ))}
      </div>
    );
  };

  return (
    <BrandDetailsWrapper className="flex-column card mb-4 mx-2">
      <CategoryTagDetails
        title={'Brands'}
        searchText={searchText}
        setSearchText={setSearchText}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onAddClick={onAddClick}
      />
      {isOpen && (
        <div className="flex-column flex-1">
          {loading ? (
            <div className="item flex items-center justify-center flex-1 my-4">
              <img alt="loading" height="40px" src={Loading} />
            </div>
          ) : (brandList.brands || []).length > 0 ? (
            <div className="flex-column details-container">
              {renderTableHeader(accountsTableHeaders)}
              <InfiniteScrollComponent
                dataLength={brandList.brands.length}
                hasMore={!brandList.last}
                fetchMoreData={fetchMoreData}>
                {(brandList.brands || []).map((brand, index) => (
                  <BrandListItem brand={brand} key={brand?.id} isLast={index === brandList.brands.length - 1} />
                ))}
              </InfiniteScrollComponent>
            </div>
          ) : debouncedSearch ? (
            <div className="flex-column details-container">
              {renderTableHeader(accountsTableHeaders)}
              <div className="no-data-wrapper flex items-center">
                <label className="medium-text main-grey-text ml-6">There are no brands added yet</label>
              </div>
            </div>
          ) : (
            <div className="flex-column details-container">
              {renderTableHeader(accountsTableHeaders)}
              <div className="no-data-wrapper flex items-center">
                <label className="medium-text main-grey-text ml-6">There are no brands added yet</label>
              </div>
            </div>
          )}
        </div>
      )}
    </BrandDetailsWrapper>
  );
};

export const BrandDetailsWrapper = styled.div`
  .details-container {
    max-height: 400px;
  }

  .header-container,
  .data-container {
    display: grid;
    grid-template-columns: minmax(150px, 1fr) minmax(250px, 1fr) minmax(200px, 200px) minmax(260px, 300px);
  }

  .header-container {
    min-height: 40px;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
  }

  .no-data-wrapper {
    min-height: 56px;
  }
`;

export default BrandDetails;
