import React from 'react';
import styled from 'styled-components';
import { getFormattedDate } from '../../helpers/utils';

const PrintLogListItem = ({ logItem = {} }) => {
  const { id: logItemId, printed_date, product, storage, storage_rule, initials, device } = logItem;

  return (
    <PrintLogListItemItemWrapper className="data-container py-2 border-bottom cursor">
      <div className="flex items-center overflow-hidden">
        <label className="medium-text font-16 one-line">{product?.name}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="medium-text font-16 one-line">{storage?.name || storage_rule?.name}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text overflow-hidden one-line">{initials}</label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text overflow-hidden one-line">
          {printed_date ? getFormattedDate(printed_date, 'MMMM DD, YYYY, h:mm a') : ''}
        </label>
      </div>
      <div className="flex items-center overflow-hidden">
        <label className="regular-text overflow-hidden one-line">{device?.name || ''}</label>
      </div>
    </PrintLogListItemItemWrapper>
  );
};

const PrintLogListItemItemWrapper = styled.div`
  min-height: 64px;
  max-height: 64px;

  &:hover {
    background: ${({ theme }) => theme.colors.backgroundColor};
  }
`;

export default PrintLogListItem;
