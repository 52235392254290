import { nanoid } from 'nanoid';
import React, { Fragment, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import OrganizationFilter from '../../components/common/organization-filter';
import ExpiryReportList from '../../components/expiry-report-list';
import PageHeader from '../../components/page-header';
import PrintLogList from '../../components/print-log-list';
import { multipleFilterTypeEnum } from '../../constant/filter-option-data';

const ReportTabList = [
  { name: 'Print log', value: 'PRINT_LOG' },
  { name: 'Expiry report', value: 'EXPIRY_REPORT' },
];

const Reports = () => {
  const [printLogFilters, setPrintLogFilters] = useState([{ id: nanoid() }]);
  const [expiryReportFilters, setExpiryReportFilters] = useState([{ id: nanoid() }]);
  const [selectedReportTab, setSelectedReportTab] = useState(ReportTabList.at(0));
  const [printLogOrganizationFilter, setPrintLogOrganizationFilter] = useState(null);
  const [expiryReportOrganizationFilter, setExpiryReportOrganizationFilter] = useState(null);

  const organizationFilter =
    selectedReportTab.value === 'PRINT_LOG' ? printLogOrganizationFilter : expiryReportOrganizationFilter;

  const onChangeOrganizationFilter = selected => {
    if (selectedReportTab.value === 'PRINT_LOG') {
      setPrintLogOrganizationFilter(selected);
      setPrintLogFilters([{ id: nanoid() }]);
      return;
    }
    if (selectedReportTab.value === 'EXPIRY_REPORT') {
      setExpiryReportOrganizationFilter(selected);
      setExpiryReportFilters([{ id: nanoid() }]);
      return;
    }
  };

  const setFilters = filters => {
    if (selectedReportTab.value === 'PRINT_LOG') {
      setPrintLogFilters(filters);
      return;
    }
    if (selectedReportTab.value === 'EXPIRY_REPORT') {
      setExpiryReportFilters(filters);
      return;
    }
  };

  return (
    <ReportsWrapper className="flex-column w-full">
      <Routes>
        <Route
          element={
            <Fragment>
              <PageHeader
                className={organizationFilter?.length ? 'items-start' : ''}
                title="Reports"
                showMultipleFilter
                multiFilterPlacement="bottom-right"
                showCustomSearchableDropdownFiled={true}
                customSearchableDropdownFiled={() => (
                  <OrganizationFilter
                    organizationFilter={organizationFilter}
                    setOrganizationFilter={onChangeOrganizationFilter}
                    isRemoveParents={true}
                  />
                )}
                organizationFilter={organizationFilter}
                multiFilter={
                  selectedReportTab.value === 'PRINT_LOG'
                    ? printLogFilters
                    : selectedReportTab.value === 'EXPIRY_REPORT'
                    ? expiryReportFilters
                    : []
                }
                setMultiFilter={setFilters}
                multiFilterFor={
                  selectedReportTab.value === 'PRINT_LOG'
                    ? multipleFilterTypeEnum.printLog
                    : selectedReportTab.value === 'EXPIRY_REPORT'
                    ? multipleFilterTypeEnum.expiryReport
                    : ''
                }
                showTabs={true}
                tabDetails={{
                  tabs: ReportTabList,
                  selected: selectedReportTab.value || '',
                  onTabChange: setSelectedReportTab,
                }}
              />
              {selectedReportTab.value === 'PRINT_LOG' && (
                <PrintLogList filters={printLogFilters} organizationFilter={organizationFilter} />
              )}
              {selectedReportTab.value === 'EXPIRY_REPORT' && (
                <ExpiryReportList filters={expiryReportFilters} organizationFilter={organizationFilter} />
              )}
            </Fragment>
          }
          path="/*"
        />
      </Routes>
    </ReportsWrapper>
  );
};

const ReportsWrapper = styled.div``;

export default Reports;
