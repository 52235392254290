import React, { Fragment } from 'react';
import { ReactComponent as InviteExpiredIcon } from '../../assets/icons/invite-expire.svg';
import { ReactComponent as Logo } from '../../assets/images/Logo.svg';

const InviteExpired = () => {
  return (
    <Fragment>
      <Logo />
      <label className="ramabhadra-regular-text text-center font-24 mt-10">
        Whoops, looks like this invitation has expired
      </label>
      <label className="regular-text main-grey-text text-center font-14 mt-4">
        Please ask your organisation to re-send the invite.
      </label>
      <InviteExpiredIcon className="mt-8" />
    </Fragment>
  );
};

export default InviteExpired;
