import styled from 'styled-components';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: ${props => props.color};
  background: ${props => props.bgColor};
  height: ${props => props.height};
  border: ${props => props.borderWidth} solid ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};

  * {
    cursor: pointer;
  }

  svg {
    margin-right: 8px;
  }

  label {
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
  }

  &.primary {
    background: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};

    label {
      color: ${({ theme }) => theme.colors.white};
    }

    &.disabled {
      background: ${({ theme }) => theme.colors.light_blue};
      border: 1px solid ${({ theme }) => theme.colors.light_blue};

      label {
        opacity: 0.5;
      }
    }
  }

  &.secondary {
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary};

    label {
      color: ${({ theme }) => theme.colors.primary};
    }

    &.disabled {
      border: 1px solid ${({ theme }) => theme.colors.disabled};

      label {
        color: ${({ theme }) => theme.colors.disabled};
      }
    }
  }

  &.additional {
    background: ${({ theme }) => theme.colors.additional};
    border: 1px solid ${({ theme }) => theme.colors.additional};

    label {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  &.negative {
    background: ${({ theme }) => theme.colors.additional_red};
    border: 1px solid ${({ theme }) => theme.colors.additional_red};

    label {
      color: ${({ theme }) => theme.colors.white};
    }

    &.disabled {
      opacity: 0.5;
    }
  }

  &.specified-width {
    width: ${({ width }) => width};
  }
`;

export default ButtonWrapper;
