import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as GpsIcon } from '../../assets/icons/contact/gps.svg';
import { ReactComponent as MailIcon } from '../../assets/icons/contact/mail.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/contact/phone.svg';
import { getContactValueByType } from '../../helpers/utils';

const DetailsHeader = () => {
  const { venueDetails } = useSelector(state => state.accounts);

  const getLocationValue = locations => {
    const primaryLocation = locations?.find(l => l.is_primary);
    return primaryLocation || locations?.[0] || {};
  };

  const { name, locations, contact_details, unique_tax_reference } = venueDetails;
  const contactEmail = getContactValueByType(contact_details, 'EMAIL');
  const contactPhone = getContactValueByType(contact_details, 'MOBILE');
  const accountLocation = getLocationValue(locations);

  return (
    <DetailsHeaderWrapper className="mb-6 mx-2 card px-6 py-4">
      <div className="flex-column row-gap-2">
        <label className="bold-text main-grey-text font-12">VENUE</label>
        <label className="semibold-text font-20">{name}</label>
      </div>
      <div className="flex-column mt-2 row-gap-2">
        <div className="flex items-center">
          <div className="flex items-center pxy-1 radius-2 icon-container primary-text">
            <PhoneIcon />
          </div>
          <label className={classNames('regular-text ml-2', !contactPhone.contact_value && 'main-grey-text')}>
            {contactPhone.contact_value || 'Not provided'}
          </label>
        </div>
        <div className="flex items-center">
          <div className="flex items-center pxy-1 radius-2 icon-container primary-text">
            <MailIcon />
          </div>
          <label className={classNames('regular-text ml-2', !contactEmail.contact_value && 'main-grey-text')}>
            {contactEmail.contact_value || 'Not provided'}
          </label>
        </div>
      </div>
      <div className="mt-2">
        <div className="flex items-start">
          <div className="flex items-center pxy-1 radius-2 icon-container primary-text">
            <GpsIcon />
          </div>
          <label className={classNames('regular-text ml-2', !accountLocation.formatted_address && 'main-grey-text')}>
            {accountLocation.formatted_address || 'Not provided'}
          </label>
        </div>
      </div>
    </DetailsHeaderWrapper>
  );
};

export const DetailsHeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(300px, 1.5fr) minmax(300px, 1.5fr);

  .icon-container {
    background-color: ${({ theme }) => theme.colors.light_grey_background};
  }
`;

export default DetailsHeader;
