import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationContext } from '../../../context/organisationContext';
import Dropdown from '../dropdown';

const OrganizationFilter = ({ organizationFilter, setOrganizationFilter, isRemoveParents = false }) => {
  const { t } = useTranslation();

  const { organizationParentChilds, organizationParentChildData } = useContext(OrganisationContext);
  const { organization, networks } = organizationParentChildData;

  const isBrand = organization?.category?.tag?.tag === 'BRAND';

  const getOrganizationParentChildIds = () => {
    const organizationOption = { label: organization?.name, value: organization?.id };
    const parentChildOptions = organizationParentChilds
      .filter(o => (isRemoveParents && isBrand ? !o.isParent : true))
      .map(org => ({
        label: org?.category?.tag?.name ? `${org?.category?.tag?.name} - ${org.name}` : org.name,
        value: org.id,
      }));
    return [organizationOption, ...parentChildOptions];
  };

  if (!(organization?.allow_networking && networks?.length > 0)) {
    return null;
  }

  return (
    <Dropdown
      className="w-fit-content ml-6"
      options={getOrganizationParentChildIds() || []}
      value={organizationFilter}
      isMulti={true}
      isSearchable={false}
      onChange={selected => {
        setOrganizationFilter(selected);
      }}
      placeholder={t('select_type', { type: 'location' })}
      customStyle={{ control: { width: 300 } }}
    />
  );
};

export default OrganizationFilter;
